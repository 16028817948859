import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Flex } from '@latitude/box';
import { CountdownTimer } from '@latitude/countdown-timer';
import { PlanTypes } from '@latitude/interest-free-plans';
import { Horizontal } from '@latitude/spacing';
import { Heading6 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { ImportantInformation } from '@latitude/important-information';
import { Footer } from '@latitude/footer';
import {
  ALIGN_ITEMS,
  BUTTON_STYLE,
  BREAKPOINT,
  COLOR,
  FLEX_DIRECTION,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';
import Navigation from './components/_navigation';
import HeroBranded from '../../../components/HeroBranded/HeroBranded';
import Layout from '../../../components/layout';
import Promo, { PromoSection } from '../../../components/Promo/Promo';
import OfferTiles from '../../../components/OfferTiles/OfferTiles';
import { getUrlFromEnv } from '../../../utils/getUrlFromEnvUtil';

import PageData from '../../../data/pages/interest-free/deal-fest.json';
import footerData from '../../../data/pages/interest-free/footer.json';
import imgHero from '../images/logo-dealfest-hype.png';
import imgHeroBg from '../images/bg-dealfest.png';
import imgHeroBgMobile from '../images/bg-dealfest-hype-mobile.png';
import imgFooter from '../../../images/gem-visa-card-illustrated.png';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { useSetState } from '@/utils/hooks';

const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa');

const DealFestPage = props => {
/** Contentful Data */
const contentfulPageData = useContext(PageContext);
const state = contentfulPageData?.updatedBody || {};
/** - END - */

return (
  <Layout
    location={props.location}
    customFooter={{
      ...footerData,
      cardData: {
        text: footerData.cardData.text,
        href: APPLY_URL,
        img: imgFooter
      }
    }}
  >
    <main className="navigation-spacer" css={COLOR.GREY6}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/deal-fest/"
        />
        <title>Deal Fest | Latitude Interest Free</title>
        <meta
          name="description"
          content="Creating the best Interest Free shopping experience for our customers."
        />
      </Helmet>

      {/* Hero */}
      <div
        css={`
          background-image: url(${imgHeroBgMobile});
          background-size: cover;
          @media (min-width: ${BREAKPOINT.SM}) {
            background-image: url(${imgHeroBg});
            .HeroContent {
              padding-top: 90px;
            }
          }
          @media (min-width: ${BREAKPOINT.MD}) {
            background-image: url(${imgHeroBg});
            .HeroContent {
              padding-top: 120px;
            }
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            background-image: url(${imgHeroBg});
            .HeroContent {
              padding-top: 150px;
            }
          }
        `}
      >
        <HeroBranded
          title={
            <>
              $150 credit back.<sup>*</sup>
              <br />
              What a deal!
            </>
          }
          text={PageData.content.heroText}
          transparentBg
          imageContent={
            <div
              css={`
                display: none;
                justify-content: center;
                @media (min-width: ${BREAKPOINT.SM}) {
                  display: flex;
                  margin-left: 50px;
                }
                @media (min-width: ${BREAKPOINT.MD}) {
                  display: flex;
                  margin-left: 120px;
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  display: flex;
                  margin-left: 200px;
                  padding-right: 30px;
                }
              `}
            >
              <img
                src={imgHero}
                alt="Latitude Deal Fest"
                css={`
                  @media (min-width: ${BREAKPOINT.SM}) {
                    height: 380px;
                  }
                  @media (min-width: ${BREAKPOINT.MD}) {
                    height: 400px;
                  }
                  @media (min-width: ${BREAKPOINT.LG}) {
                    height: 520px;
                  }
                `}
              />
            </div>
          }
          buttonProps={{
            id: 'dealfest-hero-cta',
            href: APPLY_URL,
            button: BUTTON_STYLE.PRIMARY,
            children: 'Apply for a Latitude Gem Visa',
            trackId: 'interest-free-deal-fest--hero--apply'
          }}
          trackEventData={{
            category: 'cta',
            action: 'application-link'
          }}
          trackProductId={['CCAUGEM']}
        />
      </div>

      <Navigation />

      <Flex
        justifyContent={JUSTIFY_CONTENT.CENTER}
        alignItems={ALIGN_ITEMS.CENTER}
        flexDirection={FLEX_DIRECTION.COLUMN}
        paddingBottom={PADDING.P24}
        paddingTop={PADDING.P48}
      >
        <Heading6 color={COLOR.BLACK} marginBottom={MARGIN.M16}>
          Get ready, Deal Fest starts in…
        </Heading6>
        <CountdownTimer expiry="2021-06-02T23:59:59+10:00" />
      </Flex>
      <OfferTiles />
      {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) :
      <PromoSection
        id="dealfest-promo-section"
        css={`
          background-color: ${COLOR.WHITE};
        `}
      >
        <Promo
          title={PageData.content.promoTitle}
          description={PageData.content.promoText}
          frameImage={
            <StaticImage
              src="../images/promo-dealfest.png"
              alt="interest free - deal fest"
            />
          }
          verticalAlignContent
          frameAlignment="left"
          frameBorderColor="#FFDEEF"
          ctaButtons={
            <Horizontal spacing={MARGIN.M16}>
              <AnalyticsLocationProvider location="Enjoy now, pay later with Latitude Gem Visa">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={APPLY_URL}
                  trackId="interest-free-deal-fest"
                  trackEventData={{
                    category: 'cta',
                    action: 'application-link',
                    location: 'Enjoy now, pay later with Latitude Gem Visa'
                  }}
                  trackProductId={['CCAUGEM']}
                  width="100%"
                >
                  Apply now
                </Link>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="/credit-cards/gem-visa/"
                  trackId="interest-free-deal-fest-find-out-more"
                  trackEventData={{
                    location: 'Enjoy now, pay later with Latitude Gem Visa'
                  }}
                  trackProductId={['CCAUGEM']}
                  width="100%"
                >
                  Find out more
                </Link>
              </AnalyticsLocationProvider>
            </Horizontal>
          }
        />
      </PromoSection>
      }
      <PlanTypes
        id="dealfest-plan-types-section"
        heading={PageData.content.planTypeHeading}
      />

      <ImportantInformation
        data={{
          content: {
            importantInformationSectionOne:
              PageData.content.importantInformationSectionOne,
            importantInformationSectionTwo:
              PageData.content.importantInformationSectionTwo
          }
        }}
        sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
        sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
      />
    </main>
  </Layout>
)};

export default DealFestPage;
